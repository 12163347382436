import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/auth/login/login.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'auth/log-masuk' },
  { path: 'auth/log-masuk', component: LoginComponent },
  { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'pesanan', loadChildren: () => import('./pages/order/order.module').then(m => m.OrderModule) },
  { path: 'profil-pengguna', loadChildren: () => import('./pages/user-profile/user-profile.module').then(m => m.UserProfileModule) },
  { path: 'kedai', loadChildren: () => import('./pages/businesses/businesses.module').then(m => m.BusinessesModule) },
  { path: 'data', loadChildren: () => import('./pages/data/data.module').then(m => m.DataModule) },
  { path: 'urusniaga', loadChildren: () => import('./pages/transactions/transactions.module').then(m => m.TransactionsModule) },
  { path: 'kewangan', loadChildren: () => import('./pages/finance/finance.module').then(m => m.FinanceModule) },
  { path: 'business-insights', loadChildren: () => import('./pages/business-insight/business-insight.module').then(m => m.BusinessInsightModule) },
  { path: 'fulfillment', loadChildren: () => import('./pages/fulfillment/fulfillment.module').then(m => m.FulfillmentModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
